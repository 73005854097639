var timer = false;
var fadeDuration = 500,
    $body = $('body'),
    $navbar = $('.js-navbar'),
    $slider = $('.slider-wrapper'),
    $window = $(window),
    $navbarCollapse = $navbar.find('.js-navbar-collapse'),
    $intro = $('.js-intro'),
    navbarHeight = $navbar.height(),
    windowHeight = $window.height(),
    contactFormError = false;

$(document).ready(function () {
    $slider.slick({
            prevArrow: '<div class="slick-prev"><span class="icon-sons icon-arrow-left"></span></div>',
            nextArrow: '<div class="slick-next"><span class="icon-sons icon-arrow-right"></span></div>',
            dots: true,
            infinite: true,
            autoplay: true,
            autoplaySpeed: 7000,
            accessibility: true,
            swipeToSlide: true,
            responsive: [
                {
                    breakpoint: 990,
                    settings: {
                        arrows: false,
                        dots: false
                    }
                }
            ]
        }
    );

    setTimeout(sliderTextBackgroundFix, 0);

    // On before slide change
    $slider.on('afterChange', function (event, slick, currentSlide) {
        transparentNavbar();
    });

    $slider.on('beforeChange', function (event, slick, currentSlide, nextSlide) {
        var slide = slick.$slides.get(nextSlide);
        if(!$(slide).hasClass('js-dark-slide')) {
            if ($window.scrollTop() <= 15) {
                $navbar.addClass('navbar-white');
            }
        } else {
            $navbar.removeClass('navbar-white');
        }


    });

    $body.scrollspy({target: '.js-navbar', offset: $navbar.height() + 3});

    $('a[data-target]').click(function (e) {

        var $target = $($(this).data('target')),
            offset = -($navbar.find('.navbar-header').height() || $navbar.height());

        if ($target.length > 0) {
            $body.scrollTo($target, {
                duration: fadeDuration,
                offset: offset
            });
            e.preventDefault();
        }
    });

    windowResizeEvents();

    /* Transparent navbar */

    var transparentNavbar = function () {
        var slideHeight = $intro.innerHeight() - navbarHeight - 1;
        if ($window.scrollTop() <= slideHeight) {
            $navbar.addClass('navbar-transparent');
            transparent = Math.round($window.scrollTop() * 100 / slideHeight) / 100;
            $navbar.css('background-color', 'rgba(255, 255, 255, ' + transparent + ')');
            if ($window.scrollTop() <= 15) {
                addBrightSlideClass();
            } else {
                $navbar.removeClass('navbar-white');
            }
        } else {
            $navbar.removeClass('navbar-transparent').css('background-color', '');
        }
    };

    function addBrightSlideClass() {
        if ($('.slick-current').hasClass('js-dark-slide')) {
            $navbar.removeClass('navbar-white');
        } else {
            $navbar.addClass('navbar-white');
        }
    }

    $window.on('scroll', transparentNavbar);

    $navbarCollapse.on('show.bs.collapse', function () {
        $navbar.removeClass('navbar-transparent').css('background-color', '');
        $window.off('scroll', transparentNavbar);
    });

    $navbarCollapse.on('hidden.bs.collapse', function () {
        $navbar.addClass('animate-background');
        setTimeout(function () { $navbar.removeClass('animate-background'); }, 300);
        transparentNavbar();
        $window.on('scroll', transparentNavbar);
    });

    var hideOnScrollNavbar = function () {
        if (scrollTo === false) {
            var scrollTop = $window.scrollTop();

            if (scrollTop > lastScrollTop) {
                $navbar.addClass('navbar-hidden');
            } else {
                $navbar.removeClass('navbar-hidden');
            }

            lastScrollTop = scrollTop;
        }
    };

    var collapseOnScrollNavbar = function () {
        if ($navbarCollapse.attr('aria-expanded')) {
            $navbarCollapse.collapse('hide');
        }
    };

    $window.on('scroll', hideOnScrollNavbar);

    var timeOut;
    $window.on('resize', function () {
        if (timeOut) {
            clearTimeout(timeOut);
        }

        timeOut = setTimeout(sliderTextBackgroundFix, 100);
    });

    $navbarCollapse.on('show.bs.collapse', function () {
        $navbar.removeClass('navbar-hidden');
        $window.off('scroll', hideOnScrollNavbar);
        $window.on('scroll', collapseOnScrollNavbar);
    });

    $navbarCollapse.on('hidden.bs.collapse', function () {
        $window.off('scroll', collapseOnScrollNavbar);
        $window.on('scroll', hideOnScrollNavbar);
    });
});

$(window).on('load', function () {
    // Remove spinner on load.
    $('body').removeClass('loading');

    setTimeout(function() {
        $('.body-spinner').remove();
    }, 300);
});

function sliderTextBackgroundFix() {
    var $textInstance = $('.slider-text-highlighted h1, .slider-text-highlighted h2');

    $textInstance.removeClass('prepared');

    $textInstance.each(
        function () {
            var $parent = $(this).parent();
            var text;

            if ($parent.find('.text-original').length === 0) {
                text = $(this).html().trim();
                $(this).parent().prepend('<span class="text-original hidden">' + text + '</span>');
            } else {
                text = $parent.find('.text-original').html().trim();
            }

            $(this).html('<span>' + text.split(" ").join(" </span><span>") + '</span>');

            var refPos = $(this).find('span:first-child').position().top;
            var newPos;

            $(this).find('span').each(function(index) {
                newPos = $(this).position().top;

                if (index == 0) {
                    return;
                }

                if (newPos == refPos){
                    $(this).prepend($(this).prev().text() + " ");
                    $(this).prev().remove();
                }

                refPos = newPos;
            });
        }
    ).addClass('prepared');
}

function windowResizeEvents() {
    // Avoid Android Chrome from screen jump after url bar hides.
    if (Math.abs(windowHeight - $window.height()) !== 56) {
        setContainerHeights();
        setSliderHeight();
    }

    windowHeight = $window.height()
}

function setSliderHeight() {
    var $slider = $('.slider-wrapper'),
        $sliderWrapper = $slider.parent(),
        $sliderContent = $sliderWrapper.find('.slider-content');

    $sliderWrapper.css('height', '');
    $sliderWrapper.css('height', $sliderContent.height());
}

function setContainerHeights() {
    var $sections = $('.js-container-height');

    if ($sections && $sections.length > 0) {
        $.each($sections, function (index, value) {
            var parent = $(value),
                container = parent.find('.container');
            container.css('height', 'auto');
            if ($window.width() > 950) {

                container.css('height', parent.height() - navbarHeight);
            }
        });
    }
}

$(window).resize(function () {
    window.clearTimeout(timer);
    timer = window.setTimeout(windowResizeEvents, 250);
});

$('#contact-form').submit(function (event) {
    event.preventDefault();
    var $form = $(this),
        $formData = $form.serialize(),
        $url = $form.attr('action');

    if ($('.contact-form-submit button').hasClass('disabled')) {
        return false;
    }

    $('.js-input .error').remove();
    $('.js-input').removeClass('has-error');
    $form.find('.alert.alert-success').addClass('hide');
    $.ajax({
        type: 'POST',
        url: $url,
        data: $formData,
        dataType: 'json',
        encode: true
    })
        .done(function (data) {
            $form.find('.alert.alert-success').removeClass('hide');
            $form.find('input, textarea').val('');
            grecaptcha.reset();
        }).error(function (data) {
        grecaptcha.reset();
        var response = $.parseJSON(data.responseJSON);
        $.each(response.children, function (index, value) {
            var $elementParent = $('#' + index).parent();
            if (value.errors && value.errors.length > 0) {
                $elementParent.addClass('has-error');
                $.each(value.errors, function (index, value) {
                    $elementParent.append('<div class="error">' + value + '</div>');
                });
            }
        });
    });
    event.preventDefault();
});

$('#contact-form').on('change', function () {
    validateForm();
});

var recapchaSuccess = function (response) {
    validateForm();
};

function validateForm() {
    contactFormError = false;
    $(".js-input > input, .js-input > textarea").each(function () {
        if ($(this).val() == "") {
            contactFormError = true;
        }
    });
    if (grecaptcha.getResponse().length == 0) {
        contactFormError = true;
    }
    if (!contactFormError) {
        $('.contact-form-submit button').removeClass('disabled');
    } else {
        $('.contact-form-submit button').addClass('disabled');
    }
}